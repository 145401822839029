import { render, staticRenderFns } from "./chessandcard.vue?vue&type=template&id=469bd700&scoped=true&"
import script from "./chessandcard.vue?vue&type=script&lang=js&"
export * from "./chessandcard.vue?vue&type=script&lang=js&"
import style0 from "./chessandcard.vue?vue&type=style&index=0&id=469bd700&scoped=scoped&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "469bd700",
  null
  
)

export default component.exports